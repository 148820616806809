const jsonToFormData = (jsonObject) => {
  const formData = new FormData();
  function appendFormData(data, parentKey) {
    if (Array.isArray(data)) {
      data.forEach((item, index) => {
        // Do not include index in the key
        const key = parentKey ? `${parentKey}[]` : "[]";
        appendFormData(item, key);
      });
    } else if (
      typeof data === "object" &&
      data !== null &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        if (parentKey) {
          appendFormData(data[key], `${parentKey}[${key}]`);
        } else {
          appendFormData(data[key], key);
        }
      });
    } else {
      formData.append(parentKey, data);
    }
  }

  // Append JSON data to FormData
  appendFormData(jsonObject);

  return formData;
};

const arrayToFormData = (array) => {
  const formData = new FormData();
  array.forEach((obj, index) => {
    Object.keys(obj).forEach((key) => {
      formData.append(`${key}[${index}]`, obj[key]);
    });
  });

  return formData;
};

export { jsonToFormData, arrayToFormData };
