/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  DatabaseOutlined,
  SettingOutlined,
  FileProtectOutlined,
  PieChartOutlined,
  DashboardOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import {
  Button,
  Layout,
  Menu,
  Row,
  theme,
  Col,
  Card,
  Form,
  Input,
  Avatar,
  Space,
  Image,
  Typography,
  Result,
  Flex,
  Tag,
} from "antd";
import useAuth from "./hooks/useAuth";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Containers from "./pages/containers";
import Cases from "./pages/cases";
import GraphReport from "./pages/reports/graphReport";
import DailyReport from "./pages/reports/dailyReport";
import ScanningReport from "./pages/reports/scanningReport";
import Users from "./pages/settings/users";
import Roles from "./pages/settings/roles";
import Ports from "./pages/settings/ports";
import IGMLogs from "./pages/igm-logs";
import ContainerDetails from "./pages/containers/containerDetails";
import ScannedImages from "./pages/scannedImages";
import Logo from "./assets/images/logo.png";
import FooterLogo from "./assets/images/footer-logo.png";
import ExaminedImages from "./pages/examinedImages";
import Profile from "./pages/profile";
import UploadContainer from "./pages/containers/upload";
import SpecialWatch from "./pages/specialWatch";
import { CORE_API, USER_API } from "./apis/api";
import { useAppContext } from "./context/AppContext";
import PermissionsGate, { hasPermission } from "./services/RBAC/permissionGate";
import NotScanned from "./pages/scannedImages/notScanned";
import ChapterWiseImage from "./pages/chapterWiseImage";
import ContainerStatus from "./pages/containers/checkStatus";
import CreateDailyReport from "./pages/reports/createDailyReport";
import PrivacyPolicy from "./pages/privacy/privacyPolicy";
const { Header, Content, Footer, Sider } = Layout;

const logoStyle = {
  height: 60,
  margin: 16,
  marginBottom: 45,
  textAlign: "center",
};

const App = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const [current, setCurrent] = useState("");
  const { user, login, logout, loading, setUser } = useAuth();
  const [collapsed, setCollapsed] = React.useState(false);
  const location = useLocation();
  const isAdmin =
    user?.role?.name === "admin" || user?.role?.name === "ADMIN" ? true : false;
  const { setPermissions, setUserPermissions, userPermissions } =
    useAppContext();
  const navigateTo = (item) => {
    setCurrent(item.key);
    const url = item.keyPath;
    if (url.length === 2) {
      navigate(`${url[1]}/${url[0]}`);
    } else {
      navigate(`${url[0]}`);
    }
  };

  const checkPermissions = (scopes) => {
    const permissionGranted = isAdmin
      ? true
      : hasPermission(userPermissions, scopes);
    return permissionGranted;
  };
  const items = [
    {
      key: "dashboard",
      icon: <DashboardOutlined />,
      label: "Dashboard",
      url: "/",
      disabled: !checkPermissions(["view-dashboard"]),
    },
    {
      key: "container-management",
      icon: <DatabaseOutlined />,
      label: "Container management",
      disabled: !checkPermissions([
        "view-igm-logs",
        "view-containers",
        "view-scanned-images",
        "view-examined-images",
        "view-chapter-wise-images",
        "view-special-watch",
      ]),
      children: [
        {
          key: "containers",
          label: "Containers",
          url: "/containers",
          disabled: !checkPermissions(["view-containers"]),
        },
        {
          key: "igm-logs",
          label: "IGM Logs",
          url: "/containers/igm-logs",
          disabled: !checkPermissions(["view-igm-logs"]),
        },
        {
          key: "scanned-images",
          label: "Scanned Images",
          url: "/containers/scanned-images",
          disabled: !checkPermissions(["view-scanned-images"]),
        },
        {
          key: "not-scanned-images",
          label: "Not scanned images",
          url: "/containers/not-scanned-images",
          disabled: !checkPermissions(["view-scanned-images"]),
        },
        {
          key: "examined-images",
          label: "Examined Images",
          url: "/containers/examined-images",
          disabled: !checkPermissions(["view-examined-images"]),
        },
        {
          key: "chapter-wise-images",
          label: "Chapter Wise Images",
          url: "/containers/chapter-wise-images",
          disabled: !checkPermissions(["view-chapter-wise-images"]),
        },
        {
          key: "special-watch",
          label: "Special watch",
          url: "/containers/special-watch",
          disabled: !checkPermissions(["view-special-watch"]),
        },
      ],
    },

    {
      key: "cases",
      icon: <FileProtectOutlined />,
      label: "Cases",
      url: "/cases",
      disabled: !checkPermissions(["view-cases"]),
    },
    {
      key: "reports",
      icon: <PieChartOutlined />,
      label: "Reports",
      disabled: !checkPermissions([
        "view-daily-reports",
        "view-scanning-reports",
        "view-graph-reports",
      ]),
      children: [
        {
          key: "daily-reports",
          label: "Daily reports",
          url: "/reports/daily-reports",
        },
        {
          key: "scanning-reports",

          label: "Scanning reports",
          url: "/reports/scanning-reports",
        },
        {
          key: "graph-reports",
          label: "Graph reports",
          url: "/reports/graph-reports",
        },
      ],
    },
    {
      key: "settings",
      icon: <SettingOutlined />,
      label: "Settings",
      disabled: !checkPermissions(["view-users", "view-roles", "view-ports"]),
      children: [
        {
          key: "users",

          label: "User Management",
          url: "/settings/users",
          disabled: !checkPermissions(["view-users"]),
        },
        {
          key: "roles",

          label: "Roles Management",
          url: "/settings/roles",
          disabled: !checkPermissions(["view-roles"]),
        },
      ],
    },
  ];

  const handleLogin = (values) => {
    login(values.username, values.password);
  };

  const extractController = (path) => {
    const parts = path.split("/");
    if (parts.length === 3) {
      setCurrent(parts[2]);
    }
    if (parts.length === 2) {
      setCurrent(parts[1]);
    }
    if (path === "/") {
      setCurrent("dashboard");
    }
  };

  useEffect(() => {
    extractController(location.pathname);
  });
  useEffect(() => {
    if (user) {
      CORE_API.getCoreData("permissions").then((res) => {
        const permissions = res.data.map((permission) => permission.name);
        setPermissions(permissions);
      });
    }
  }, []);

  useEffect(() => {
    if (user) {
      USER_API.getUser(user.id).then((response) => {
        if (response.data) {
          const userPermissions = response?.data?.role?.permissions.map(
            (permission) => permission.name
          );
          setUser(response.data);
          setUserPermissions(userPermissions);
        } else {
          setUser(null);
          navigate("/");
        }
      });
    }
  }, []);

  return user && userPermissions ? (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
        width={250}
        breakpoint="lg"
        collapsedWidth="80"
        theme="dark"
        collapsible={true}
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          setCollapsed(collapsed);
        }}
      >
        <div style={logoStyle}>
          <Image
            src={FooterLogo}
            preview={false}
            height={collapsed ? 20 : 55}
          />
          <Tag style={{ marginTop: 20 }} color="blue">
            {user?.port?.name}
          </Tag>
        </div>

        <Menu
          theme="dark"
          mode="inline"
          items={items}
          selectedKeys={[current]}
          onClick={navigateTo}
        />
      </Sider>
      <Layout style={{ marginLeft: collapsed ? "80px" : "250px" }}>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Row justify="space-between">
            <Col>
              <Typography.Text>
                Hello , {user ? user.name : "User"}
              </Typography.Text>
            </Col>

            <Col>
              <Space size="large">
                <Avatar
                  style={{
                    backgroundColor: "#00a2ae",
                    verticalAlign: "middle",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/profile")}
                  size="small"
                  gap="middle"
                >
                  {user?.name.split("")[0]}
                </Avatar>
                <Button
                  danger
                  type="text"
                  icon={<PoweroffOutlined />}
                  onClick={() => logout()}
                />
              </Space>
            </Col>
          </Row>
        </Header>
        <Content style={{ margin: "24px 16px 0" }}>
          <Routes>
            <Route
              path="/"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["view-dashboard"]}
                >
                  <Dashboard />
                </PermissionsGate>
              }
            />
            <Route
              path="/dashboard"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["view-dashboard"]}
                >
                  <Dashboard />
                </PermissionsGate>
              }
            />
            <Route
              path="/container-management/containers"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["view-containers"]}
                >
                  <Containers />
                </PermissionsGate>
              }
            />
            <Route
              path="/container-management/upload-container-excel"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["upload-container"]}
                >
                  <UploadContainer />
                </PermissionsGate>
              }
            />
            <Route
              path="/container-management/containers/:id"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["view-containers"]}
                >
                  <ContainerDetails />
                </PermissionsGate>
              }
            />

            <Route
              path="/container-management/igm-logs"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["view-igm-logs"]}
                >
                  <IGMLogs />
                </PermissionsGate>
              }
            />

            <Route
              path="/container-management/scanned-images"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["view-scanned-images"]}
                >
                  <ScannedImages />
                </PermissionsGate>
              }
            />
            <Route
              path="/container-management/examined-images"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["view-examined-images"]}
                >
                  <ExaminedImages />
                </PermissionsGate>
              }
            />
            <Route
              path="/container-management/chapter-wise-images"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["view-chapter-wise-images"]}
                >
                  <ChapterWiseImage />
                </PermissionsGate>
              }
            />
            <Route
              path="/container-management/not-scanned-images"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["view-scanned-images"]}
                >
                  <NotScanned />
                </PermissionsGate>
              }
            />
            <Route
              path="/container-management/special-watch"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["view-special-watch"]}
                >
                  <SpecialWatch />
                </PermissionsGate>
              }
            />
            <Route
              path="/cases"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["view-cases"]}
                >
                  <Cases />
                </PermissionsGate>
              }
            />
            <Route
              path="/reports/graph-reports"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["view-graph-reports"]}
                >
                  <GraphReport />
                </PermissionsGate>
              }
            />
            <Route
              path="/reports/daily-reports"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["view-daily-reports"]}
                >
                  <DailyReport />
                </PermissionsGate>
              }
            />
            <Route
              path="/reports/daily-reports/create"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["create-daily-reports"]}
                >
                  <CreateDailyReport />
                </PermissionsGate>
              }
            />
            <Route
              path="/reports/scanning-reports"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["view-scanning-reports"]}
                >
                  <ScanningReport />
                </PermissionsGate>
              }
            />
            <Route
              path="/settings/users"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["view-users"]}
                >
                  <Users />
                </PermissionsGate>
              }
            />
            <Route
              path="/settings/roles"
              element={
                <PermissionsGate
                  RenderError={() => <p>You shall not pass!</p>}
                  scopes={["view-roles"]}
                >
                  <Roles />
                </PermissionsGate>
              }
            />
            <Route path="/settings/ports" element={<Ports />} />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="*"
              element={
                <Result
                  status={404}
                  title="404"
                  subTitle="Sorry, the page you visited does not exist."
                />
              }
            />
          </Routes>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          JNPA ©2024. Developed and Maintained by{" "}
          <Typography.Link href="https://www.iwebs.co.in" target="_blank">
            iWebs
          </Typography.Link>{" "}
        </Footer>
      </Layout>
    </Layout>
  ) : (
    <Layout style={{ minHeight: "100vh" }}>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    textAlign: "center",
                    background: "#093461",
                    minHeight: "87vh",
                  }}
                >
                  <Image
                    preview={false}
                    src={FooterLogo}
                    width={400}
                    style={{ marginTop: 350 }}
                  />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ padding: 20, textAlign: "center" }}
                >
                  <Flex
                    justify="flex-end"
                    align="center"
                    style={{ marginBottom: 100 }}
                  >
                    <Typography.Link
                      strong
                      onClick={() => navigate("/check-container-status")}
                    >
                      Check Container Status
                    </Typography.Link>
                  </Flex>
                  <Image src={Logo} width={100} preview={false} />
                  <Typography.Title level={5}>
                    CSD Portal - JNPA
                  </Typography.Title>
                  <Card
                    headStyle={{ border: "none" }}
                    style={{ textAlign: "center" }}
                  >
                    <Form layout="vertical" onFinish={handleLogin}>
                      <Form.Item
                        label="Email / SSO ID / Mobile"
                        name="username"
                        rules={[
                          {
                            required: true,
                            message: "Please input your username!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Email , username or mobile"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your username!",
                          },
                        ]}
                      >
                        <Input.Password
                          size="large"
                          placeholder="Enter your password"
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          block
                          size="large"
                          type="primary"
                          htmlType="submit"
                          loading={loading}
                        >
                          Login
                        </Button>
                      </Form.Item>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </>
          }
        />
        <Route path="/check-container-status" element={<ContainerStatus />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/check-container-status" element={<ContainerStatus />} />
        <Route
          path="*"
          element={
            <Result
              status={404}
              title="404"
              subTitle="Sorry, the page you visited does not exist."
            />
          }
        />
      </Routes>
      <Footer style={{ background: "#fff" }}>
        <Flex justify="space-between" align="center">
          <Typography.Text>
            JNPA ©{new Date().getFullYear()}. Developed and Maintained by{" "}
            <Typography.Link href="https://www.iwebs.co.in" target="_blank">
              iWebs
            </Typography.Link>
            <Typography.Paragraph>
              For any technical support Contact us on +91-9884094592 or Mail us
              at info@iwebs.co.in.
            </Typography.Paragraph>
          </Typography.Text>
          <Typography.Text>
            <Space>
              <Typography.Link strong href="/privacy-policy" target="_blank">
                Privacy Policy
              </Typography.Link>
              <Typography.Link
                strong
                href="https://www.iwebs.co.in"
                target="_blank"
              >
                Disclaimer
              </Typography.Link>
            </Space>
          </Typography.Text>
        </Flex>
      </Footer>
    </Layout>
  );
};

export default App;
