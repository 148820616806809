import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Card,
  Table,
  Space,
  Typography,
  Tag,
  Image,
  Form,
  Drawer,
  Select,
  Spin,
  Input,
  Upload,
  Button,
  message,
  Tooltip,
  Row,
  Col,
  DatePicker,
  Alert,
} from "antd";
import { Link } from "react-router-dom";
import SCANNEDIMG_API from "../../apis/scannedImgAPI";
import debounce from "lodash/debounce";
import Cookies from "js-cookie";
import {
  UploadOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { jsonToFormData } from "../../utils";
import PermissionsGate from "../../services/RBAC/permissionGate";
import CONTAINER_API from "../../apis/containerAPI";
import dayjs from "dayjs";
import { CORE_API } from "../../apis/api";

const ScannedImage = (props) => {
  const [scannedImage, setScannedImage] = useState({});
  const [fileList, setFileList] = useState([]);
  const [scanners, setScanners] = useState([]);
  const [params, setParams] = useState({});
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [notScanned, setNotScanned] = useState(false);
  const [form] = Form.useForm();
  const [searchedContainers, setSearchedContainers] = useState({});
  const [loadingImages, setLoadingImages] = useState(false);
  const imageprops = {
    multiple: true,
    onRemove: (file) => {
      console.log("file", file);
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      const name = file.name.split(".")[0];
      setFileList(newFileList);
      delete searchedContainers[name];
    },
    beforeUpload: (file) => {
      return false;
    },
    onChange: async (info) => {
      setLoadingImages(true);
      setFileList(info.fileList);
      const container_ids = info.fileList
        .map((file) => file.name.split(".")[0])
        .join(",");
      const response = await CONTAINER_API.searchContainers({ container_ids });
      setSearchedContainers(response.data);
      setLoadingImages(false);
    },
    fileList,
  };

  const getScanners = () => {
    CORE_API.getCoreData("scanners").then((response) => {
      setScanners(response.data);
    });
  };
  const getScannedImage = useCallback(() => {
    setLoading(true);
    const newParams = Object.assign(params, {
      container_number: props.container_id,
      is_not_scanned: false,
    });
    SCANNEDIMG_API.getScannedImage(newParams)
      .then((response) => {
        setScannedImage(response.data);
        setError(false);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params, props.container_id]);

  function checkSafeSealNumber(obj) {
    for (const key in obj) {
      if (!obj[key].hasOwnProperty("safe_seal_number")) {
        return false;
      }
    }
    return true;
  }

  const save = async (values) => {
    const container_ids = Object.keys(searchedContainers).filter(
      (key) => searchedContainers[key] !== null
    );

    if (values.scanning_result === "mismatch") {
      const isSafeSealPresent = checkSafeSealNumber(searchedContainers);
      console.log(isSafeSealPresent, "isSafeSealPresent");
      if (!isSafeSealPresent) {
        message.error("Please add safe seal numbe for all containers");
        return false;
      }
    }

    const data = container_ids.map((container_id) => {
      const container = searchedContainers[container_id];

      return {
        ...values,
        scanning_date: dayjs(values.scanning_date).format("YYYY-MM-DD"),
        container_number: container.id,
        container_no: container.container_number,
        safe_seal_number: container.safe_seal_number,
      };
    });
    const fileMap = {};
    fileList.forEach((file) => {
      const containerNumber = file.name.split(".")[0];
      fileMap[containerNumber] = { ...file };
    });

    try {
      setSubmitting(true);
      data.forEach(async (item) => {
        const formData = jsonToFormData(item);
        const targetFile = fileList.find(
          (file) => file.name.split(".")[0] === item.container_no
        );
        formData.append("scanned_images", targetFile.originFileObj);
        await SCANNEDIMG_API.createScannedImage(formData)
          .then((response) => {
            console.log("res", response);
          })
          .catch((error) => {
            message.error("Something went wrong");
          });
        setSubmitting(false);
        message.success("Scanned images uploaded sucessfully");
        setFileList([]);
        form.resetFields();
        getScannedImage();
        setOpen(false);
      });
    } catch (e) {
      message.error("Something went wrong");
    }
  };
  const toggleMark = (id, value) => {
    SCANNEDIMG_API.toggleMark(id, value).then((response) => {
      message.success(
        value
          ? "Container marked for special watch successfully"
          : "Container un-marked for special watch successfully"
      );
      getScannedImage();
    });
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "images",
      key: "images",
      render: (text, row) => (
        <Image.PreviewGroup items={row.images}>
          <Image width={75} title="View" src={row.images[0]} />
        </Image.PreviewGroup>
      ),
    },
    {
      title: "Container number",
      dataIndex: "container_number",
      key: "container_number",
      render: (text, row) =>
        row.container_number ? (
          <Link
            to={`/container-management/containers/${row?.container_number?._id}`}
          >
            <Typography.Link>
              {row?.container_number?.container_number || "N/a"}
            </Typography.Link>
          </Link>
        ) : (
          "N/a"
        ),
    },

    {
      title: "IGM Number",
      dataIndex: "igm_number",
      key: "igm_number",
      render: (text, row) => row?.container_number?.igm_number || "N/a",
    },
    {
      title: "Scanned date",
      dataIndex: "scanning_date",
      key: "scanning_date",
      render: (text, row) => dayjs(row?.scanning_date).format("DD-MM-YYYY"),
    },
    {
      title: "Shift",
      dataIndex: "shift",
      key: "shift",
      render: (text, row) => row?.shift,
    },
    {
      title: "Scanner Type",
      dataIndex: "scanner_type",
      key: "scanner_type",
      render: (text, row) => row?.scanner_type,
    },
    {
      title: "Scanning result",
      dataIndex: "scanning_result",
      key: "scanning_result",
    },
    {
      title: "CSD seal no",
      dataIndex: "safe_seal_number",
      key: "safe_seal_number",
      render: (text, row) => row?.safe_seal_number || "N/a",
    },
    {
      title: "Special Watch",
      dataIndex: "is_special_watch",
      key: "is_special_watch",
      render: (text) =>
        text ? <Tag color="red">Yes</Tag> : <Tag color="green">No</Tag>,
    },
    {
      title: "Not scanned",
      dataIndex: "is_not_scanned",
      key: "is_not_scanned",
      render: (text) =>
        text ? <Tag color="red">Yes</Tag> : <Tag color="green">No</Tag>,
    },

    {
      title: "Uploaded by",
      dataIndex: "created_by",
      key: "created_by",
      render: (text, row) => (
        <span>
          {text?.name} ({text?.employee_id})
        </span>
      ),
    },
    {
      title: "Mark special watch",
      dataIndex: "is_special_watch",
      key: "is_special_watch",
      render: (text, row) =>
        !text ? (
          <Tooltip title="Mark Special watch">
            <Button
              type="text"
              icon={<EyeOutlined />}
              onClick={() => toggleMark(row._id, true)}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Unmark Special watch">
            <Button
              type="text"
              danger
              icon={<EyeInvisibleOutlined />}
              onClick={() => toggleMark(row._id, false)}
            />
          </Tooltip>
        ),
    },
  ];

  useEffect(() => {
    getScannedImage();
  }, [getScannedImage, params]);

  useEffect(() => {
    getScanners();
  }, []);

  console.log("searchedContainers", searchedContainers);

  const setSealNo = (safeAreaValue, containerNumber) => {
    const updatedData = { ...searchedContainers };
    if (updatedData.hasOwnProperty(containerNumber)) {
      updatedData[containerNumber]["safe_seal_number"] = safeAreaValue;
      setSearchedContainers(updatedData);
    } else {
      console.log("Container number not found.");
    }
  };

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }} size="middle">
        <Card
          title={`Scanned Images (${scannedImage.totalResults || 0})`}
          bodyStyle={{ padding: 0 }}
          extra={
            <PermissionsGate scopes={["create-scanned-images"]}>
              <Button type="default" onClick={() => setOpen(true)}>
                Add
              </Button>
            </PermissionsGate>
          }
        >
          <Table
            dataSource={scannedImage.results}
            columns={columns}
            loading={loading}
            rowKey="id"
            pagination={{
              current: scannedImage.page,
              pageSize: scannedImage.limit,
              total: scannedImage.totalResults,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              onChange: (value) => {
                console.log("value", value);
                setParams({ ...params, page: value });
              },
            }}
          />
        </Card>
      </Space>
      <Drawer
        title="Upload Scanned images"
        maskClosable={false}
        width={1150}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Form form={form} layout="vertical" onFinish={save}>
          <Row gutter={[32, 32]}>
            <Col sm={12}>
              <Form.Item
                name="shift"
                label="Shift"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Shift"
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    console.log("value", value);
                  }}
                >
                  <Select.Option value="day"> Day</Select.Option>
                  <Select.Option value="night"> Night</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                name="scanner_type"
                label="Scanner Type"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Scanner type"
                  onChange={(value) => {
                    console.log("value", value);
                  }}
                >
                  {scanners.map((scanner) => (
                    <Select.Option
                      key={scanner._id}
                      value={`${scanner.name}(${scanner.code})`}
                    >
                      {scanner.name} ({scanner.code})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col sm={12}>
              <Form.Item
                name="scanning_result"
                label="Scanning Result"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Scanning result"
                  onChange={(value) => {
                    console.log("value", value);
                  }}
                >
                  <Select.Option value="clean"> Clean</Select.Option>
                  <Select.Option value="mismatch"> Mismatch</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item name="scanning_date" label="Scanning Date">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            {!notScanned && (
              <Col sm={24}>
                <Form.Item label="Select scanned images">
                  <Upload
                    {...imageprops}
                    accept="image/png, image/jpeg"
                    itemRender={(originNode, file) => {
                      return null;
                    }}
                  >
                    <Button block icon={<UploadOutlined />}>
                      Select Files
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Card loading={loadingImages}>
            {fileList.length > 0 && (
              <Space
                style={{ width: "100%" }}
                direction="vertical"
                size="large"
              >
                {fileList.map((file, index) => {
                  return (
                    <Row key={file.uid} gutter={32}>
                      <Col sm={6}>
                        <Typography.Text mark>
                          <Image
                            src={URL.createObjectURL(file.originFileObj)}
                          />
                        </Typography.Text>
                      </Col>
                      <Col sm={12} style={{ textAlign: "center" }}>
                        <Typography.Text>
                          {searchedContainers[file.name.split(".")[0]] ? (
                            `${
                              searchedContainers[file.name.split(".")[0]]
                                .container_number
                            } => ${
                              searchedContainers[file.name.split(".")[0]]
                                .igm_number
                            }`
                          ) : (
                            <Alert
                              type="error"
                              message="No Container found for this image"
                            />
                          )}
                        </Typography.Text>

                        {form.getFieldValue("scanning_result") ===
                          "mismatch" && (
                          <Input
                            placeholder="CSD seal no"
                            onChange={(event) =>
                              setSealNo(
                                event.target.value,
                                searchedContainers[
                                  file.name.split(".")[0]
                                ].container_number.split("(")[0]
                              )
                            }
                          />
                        )}
                      </Col>
                      <Col sm={6}>
                        <Button
                          size="small"
                          type="text"
                          danger
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            imageprops.onRemove(file);
                          }}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  );
                })}
              </Space>
            )}
          </Card>

          {notScanned && (
            <Form.Item label="Reason" name="reson">
              <Input.TextArea />
            </Form.Item>
          )}

          {fileList.length > 0 && (
            <Form.Item>
              <Button
                style={{ marginTop: 20 }}
                type="primary"
                htmlType="submit"
                block
                loading={submitting}
              >
                Submit
              </Button>
            </Form.Item>
          )}
        </Form>
      </Drawer>
    </>
  );
};

export const ContainerAutoComplete = ({
  fetchOptions,
  debounceTimeout = 300,
  ...props
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      showSearch
      allowClear
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
};

const fetchData = async (keyword) => {
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/containers/search?keyword=${keyword}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("authToken")}`,
      },
    }
  )
    .then((response) => response.json())
    .then((body) => {
      return body.map((item) => ({
        label: `${item.container_number} - ${item.igm_number}`,
        value: item.id,
      }));
    });
};

export default ScannedImage;
