import axios from "./axios";

const SCANNEDIMG_API = {
  getScannedImage: (params) =>
    axios({
      url: `/containers/scanned-images`,
      method: "GET",
      params,
    }),

  createScannedImage: (data) =>
    axios({
      url: `/containers/scanned-images`,
      method: "POST",
      data,
    }),

  toggleMark: (id, value) =>
    axios({
      url: `/containers/${id}/toggle-mark-spacial-watch`,
      method: "PATCH",
      data: { is_special_watch: value },
    }),
};

export default SCANNEDIMG_API;
